@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent;
}

@font-face {
  font-family: "nolan";
  src: url('/assets/fonts/NolanMedium.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ChelseaMarket";
  src: url('/assets/fonts/ChelseaMarket-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

.font-brand-formkiq {
  font-family: 'nolan', 'sans';
}

.font-brand-24hour {
  font-family: 'ChelseaMarket', 'sans';
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.box-shadow-bottom {
  box-shadow: 0 4px 2px -2px rgba(0,0,0,0.2);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 16px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}